
import { createSlice } from "@reduxjs/toolkit";

export const AuthReducer = createSlice({
  name: "auth",
  initialState: {
    auth: null,
  },
  reducers: {
    authData: (state, action) => {
      state.auth = action.payload;
    },
  },
});

export const { authData } = AuthReducer.actions;

export default AuthReducer.reducer;


 