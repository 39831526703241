
import { Link } from "react-router-dom";
import logo from "../assets/Logo.png";

export const Logo = () => {
  return (
    <Link to={"/"}>
      <img src={logo} className="w-20 h-20" alt="logo" />
    </Link>
  );
};

 