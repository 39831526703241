
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';

import { Layout } from '../../components/layout';
import { BeforeFooter } from '../../components/other/beforeFooter';
import { openModal, setObjeName } from '../../redux/generalReducer';

import useDocumentTitle from '../../hooks/useTitle';
import useScrollTop from '../../hooks/useScrollToTop';

function About () {

  const contents = useSelector((state) => state.contact.data);
  const corevalues = useSelector((state) => state.corevalues.data);
  const about = useSelector((state) => state.general.about);
  const editContent = useSelector((state) => state.general.editcontent);

  useDocumentTitle("About | MozartrenDK");
  useScrollTop();

  const dispatch = useDispatch();

  const toggleEditModal = (objectName, fieldName, fieldValue) => {
    dispatch(setObjeName(objectName));
    dispatch(openModal({ field: fieldName, value: fieldValue }));
  };

  return (
    <Layout>
      <div className="w-full flex items-center justify-center h-full lg:h-[650px] p-4 aboutBackground bg-blend-overlay py-10 px-8 md:h-[636px] bg-white">
        <div className="w-full max-w-7xl grid grid-cols-1 gap-16">
          <div className="flex flex-col gap-4 items-start md:items-center lg:items-center justify-center">
            <span className="pageSpantitle !capitalize">Om Os</span>
            <div className="flex flex-col gap-4 items-start md:items-center lg:items-center">
              <h2 className="pageHeaderTitle !text-white w-full text-2xl !text-left lg:!text-center !leading-[38px] lg:!text-4xl lg:!max-w-xs lg:!leading-[42px]">
                {about.heading}
              </h2>

              {editContent ? (
                <div className="relative">
                  <button
                    onClick={() =>
                      toggleEditModal(
                        "about",
                        "about_description",
                        contents?.about
                      )
                    }
                    className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[1.2rem] -left-[25rem]"
                  >
                    <Icon icon="tabler:edit" width="20" height="20" />
                  </button>
                </div>
              ) : (
                <></>
              )}
              <p className="pageDescription w-full !text-white !text-left lg:!text-center !max-w-[45rem] !font-light !text-lg !leading-[25px]">
                {contents?.about}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center h-full p-4 py-10 lg:py-14 px-8 bg-white">
        <div className="p-5 bg-primaryblue w-full max-w-7xl rounded-[10px] h-full lg:h-[334px] flex flex-col justify-center items-center">
          <div className="p-5 flex flex-col gap-6">
            <h2 className="font-semibold text-white text-xl capitalize leading-normal">
              Vores mission
            </h2>

            {editContent ? (
              <div className="relative">
                <button
                  onClick={() =>
                    toggleEditModal(
                      "about",
                      "mission",
                      contents?.mission_statement
                    )
                  }
                  className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[1.5rem] -left-10"
                >
                  <Icon icon="tabler:edit" width="20" height="20" />
                </button>
              </div>
            ) : (
              <></>
            )}
            <p className="pageHeaderTitle w-full text-4xl lg:text-[40px] !max-w-4xl !leading-[42px] lg:!leading-[46px]">
              {contents?.mission_statement}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center h-full p-4 py-10 lg:py-14 px-8 bg-white">
        <div className="w-full max-w-7xl flex items-start justify-center gap-4 flex-col">
          <span className="pageSpantitle !capitalize !text-center !w-full ">
            Vores vision
          </span>

          <div className="flex items-center justify-center w-full">
            {editContent ? (
              <div className="relative">
                <button
                  onClick={() =>
                    toggleEditModal("about", "values", contents?.vision)
                  }
                  className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[-2rem] -left-10"
                >
                  <Icon icon="tabler:edit" width="20" height="20" />
                </button>
              </div>
            ) : (
              <></>
            )}
            <h2 className="pageHeaderTitle !self-center !w-full lg:!max-w-6xl !text-center text-3xl !leading-[38px] !max-w-full lg:!text-4xl lg:!leading-[42px]">
              {contents?.vision}
            </h2>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 mt-8 gap-7 w-full">
            <div className="border-[1px] border-secondaryyellow p-6 flex flex-col gap-4 rounded-[10px] shadow-md bg-white w-full">
              <div className="border-[1px] border-secondaryblue w-fit rounded-full p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                >
                  <path
                    d="M43.5201 37.3673C43.5201 38.4057 42.6753 39.2417 41.6457 39.2417H32.2561C30.771 39.2417 29.8877 37.6213 30.6633 36.3729L32.6257 33.2225H28.4985V38.3089C28.4985 39.3385 27.6625 40.1833 26.6241 40.1833C25.5857 40.1833 24.7497 39.3385 24.7497 38.3089V31.3481C24.7497 30.3097 25.5857 29.4649 26.6241 29.4649H31.5169C31.0153 28.1977 30.5577 27.2208 30.1705 26.4817C27.8284 28.4583 25.0012 29.2218 22.2065 27.9689H22.2329C23.5605 27.9689 25.0297 26.5659 23.5353 24.4313C25.3049 25.3341 26.8617 24.5251 28.2873 23.1113C28.1553 22.2313 28.4545 21.2897 29.1673 20.6297C30.3025 19.5737 32.1065 19.6529 33.1625 20.7969C33.5409 21.2017 35.5473 23.5601 37.7385 30.0985C37.9489 30.7014 37.9757 31.7399 37.6065 32.3337L35.6353 35.4929H41.6457C42.6753 35.4929 43.5201 36.3289 43.5201 37.3673Z"
                    fill="#FFDC2C"
                  />
                  <path
                    d="M28.9701 18.594C30.7844 18.594 32.2551 17.1232 32.2551 15.309C32.2551 13.4947 30.7844 12.0239 28.9701 12.0239C27.1558 12.0239 25.6851 13.4947 25.6851 15.309C25.6851 17.1232 27.1558 18.594 28.9701 18.594Z"
                    fill="#FFDC2C"
                  />
                  <path
                    d="M17.8963 10.4815H3.21403V7.61398C3.87543 7.14098 5.33104 6.42721 6.79915 7.89452C6.99002 8.0854 7.29934 8.0854 7.49021 7.89452C7.68108 7.70365 7.68108 7.39433 7.49021 7.20346C6.72056 6.43381 5.91967 6.11138 5.16895 6.05444V4.6167H1.27979C1.27979 12.2263 1.27979 5.20463 1.27979 19.2786H8.12196C12.1089 19.2786 15.3921 16.2927 15.8741 12.4364H17.8963C18.4362 12.4364 18.8737 11.9988 18.8737 11.459C18.8738 10.919 18.4362 10.4815 17.8963 10.4815ZM12.758 20.256C12.3492 20.256 12.0184 20.5871 12.0184 20.9955V25.5404C12.0184 26.9761 10.8506 28.1443 9.41486 28.1443C7.97914 28.1443 6.81059 26.9762 6.81059 25.5404V23.5222C6.81059 23.1138 6.47979 22.7826 6.07103 22.7826H2.75871V22.1355H1.27979V25.0937H2.75871V24.2616H5.33148V25.5403C5.33148 27.7918 7.16303 29.6232 9.41486 29.6232C11.666 29.6232 13.4974 27.7918 13.4974 25.5403V20.9955C13.4974 20.5871 13.1667 20.256 12.758 20.256ZM19.628 18.5577C18.9397 17.5834 17.5795 17.3495 16.6053 18.0378C16.5521 18.0753 16.5078 18.1066 16.4698 18.1467L18.0038 19.1341C18.3682 19.368 18.4653 19.8437 18.2404 20.2018C18.0066 20.5661 17.5307 20.6632 17.1664 20.4293L15.7179 19.4877C15.6331 20.0255 15.7474 20.582 16.0853 21.0603C16.5449 21.7107 17.3034 22.022 18.047 21.9584L21.3506 26.6344C21.6961 27.1236 22.3728 27.2399 22.8619 26.8943C23.3511 26.5488 23.4674 25.8721 23.1218 25.383L19.8186 20.7074C20.1275 20.0277 20.0877 19.2083 19.628 18.5577Z"
                    fill="#FFDC2C"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-2">
                {editContent ? (
                  <div className="relative">
                    <button
                      onClick={() =>
                        toggleEditModal(
                          "about",
                          "values_1_title",
                          corevalues && corevalues[0]?.value
                        )
                      }
                      className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[0.5rem] -left-10"
                    >
                      <Icon icon="tabler:edit" width="20" height="20" />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                <h3 className="font-semibold text-xl leading-normal text-secondaryblue">
                  {corevalues && corevalues[0]?.value}
                </h3>

                {editContent ? (
                  <div className="relative">
                    <button
                      onClick={() =>
                        toggleEditModal(
                          "about",
                          "values_1_description",
                          corevalues && corevalues[0]?.description
                        )
                      }
                      className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[0.5rem] -left-10"
                    >
                      <Icon icon="tabler:edit" width="20" height="20" />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                <p className="text-secondaryblue leading-[25px] text-base font-light">
                  {corevalues && corevalues[0]?.description}
                </p>
              </div>
            </div>

            <div className="border-[1px] border-secondarypink p-6 flex flex-col gap-4 rounded-[10px] shadow-md bg-white w-full">
              <div className="border-[1px] border-secondaryblue w-fit rounded-full p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                >
                  <path
                    d="M43.5201 37.3673C43.5201 38.4057 42.6753 39.2417 41.6457 39.2417H32.2561C30.771 39.2417 29.8877 37.6213 30.6633 36.3729L32.6257 33.2225H28.4985V38.3089C28.4985 39.3385 27.6625 40.1833 26.6241 40.1833C25.5857 40.1833 24.7497 39.3385 24.7497 38.3089V31.3481C24.7497 30.3097 25.5857 29.4649 26.6241 29.4649H31.5169C31.0153 28.1977 30.5577 27.2208 30.1705 26.4817C27.8284 28.4583 25.0012 29.2218 22.2065 27.9689H22.2329C23.5605 27.9689 25.0297 26.5659 23.5353 24.4313C25.3049 25.3341 26.8617 24.5251 28.2873 23.1113C28.1553 22.2313 28.4545 21.2897 29.1673 20.6297C30.3025 19.5737 32.1065 19.6529 33.1625 20.7969C33.5409 21.2017 35.5473 23.5601 37.7385 30.0985C37.9489 30.7014 37.9757 31.7399 37.6065 32.3337L35.6353 35.4929H41.6457C42.6753 35.4929 43.5201 36.3289 43.5201 37.3673Z"
                    fill="#FF5AC0"
                  />
                  <path
                    d="M28.9701 18.594C30.7844 18.594 32.2551 17.1232 32.2551 15.309C32.2551 13.4947 30.7844 12.0239 28.9701 12.0239C27.1558 12.0239 25.6851 13.4947 25.6851 15.309C25.6851 17.1232 27.1558 18.594 28.9701 18.594Z"
                    fill="#FF5AC0"
                  />
                  <path
                    d="M17.8963 10.4815H3.21403V7.61398C3.87543 7.14098 5.33104 6.42721 6.79915 7.89452C6.99002 8.0854 7.29934 8.0854 7.49021 7.89452C7.68108 7.70365 7.68108 7.39433 7.49021 7.20346C6.72056 6.43381 5.91967 6.11138 5.16895 6.05444V4.6167H1.27979C1.27979 12.2263 1.27979 5.20463 1.27979 19.2786H8.12196C12.1089 19.2786 15.3921 16.2927 15.8741 12.4364H17.8963C18.4362 12.4364 18.8737 11.9988 18.8737 11.459C18.8738 10.919 18.4362 10.4815 17.8963 10.4815ZM12.758 20.256C12.3492 20.256 12.0184 20.5871 12.0184 20.9955V25.5404C12.0184 26.9761 10.8506 28.1443 9.41486 28.1443C7.97914 28.1443 6.81059 26.9762 6.81059 25.5404V23.5222C6.81059 23.1138 6.47979 22.7826 6.07103 22.7826H2.75871V22.1355H1.27979V25.0937H2.75871V24.2616H5.33148V25.5403C5.33148 27.7918 7.16303 29.6232 9.41486 29.6232C11.666 29.6232 13.4974 27.7918 13.4974 25.5403V20.9955C13.4974 20.5871 13.1667 20.256 12.758 20.256ZM19.628 18.5577C18.9397 17.5834 17.5795 17.3495 16.6053 18.0378C16.5521 18.0753 16.5078 18.1066 16.4698 18.1467L18.0038 19.1341C18.3682 19.368 18.4653 19.8437 18.2404 20.2018C18.0066 20.5661 17.5307 20.6632 17.1664 20.4293L15.7179 19.4877C15.6331 20.0255 15.7474 20.582 16.0853 21.0603C16.5449 21.7107 17.3034 22.022 18.047 21.9584L21.3506 26.6344C21.6961 27.1236 22.3728 27.2399 22.8619 26.8943C23.3511 26.5488 23.4674 25.8721 23.1218 25.383L19.8186 20.7074C20.1275 20.0277 20.0877 19.2083 19.628 18.5577Z"
                    fill="#FF5AC0"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-2">
                {editContent ? (
                  <div className="relative">
                    <button
                      onClick={() =>
                        toggleEditModal(
                          "about",
                          "values_2_title",
                          corevalues && corevalues[1]?.value
                        )
                      }
                      className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[0.5rem] -left-10"
                    >
                      <Icon icon="tabler:edit" width="20" height="20" />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                <h3 className="font-semibold text-xl leading-normal text-secondaryblue">
                  {corevalues && corevalues[1]?.value}
                </h3>

                {editContent ? (
                  <div className="relative">
                    <button
                      onClick={() =>
                        toggleEditModal(
                          "about",
                          "values_2_description",
                          corevalues && corevalues[1]?.description
                        )
                      }
                      className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[0.5rem] -left-10"
                    >
                      <Icon icon="tabler:edit" width="20" height="20" />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                <p className="text-secondaryblue leading-[25px] text-base font-light">
                  {corevalues && corevalues[1]?.description}
                </p>
              </div>
            </div>

            <div className="border-[1px] border-primaryblue p-6 flex flex-col gap-4 rounded-[10px] shadow-md bg-white w-full">
              <div className="border-[1px] border-secondaryblue w-fit rounded-full p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                >
                  <path
                    d="M43.5201 37.3673C43.5201 38.4057 42.6753 39.2417 41.6457 39.2417H32.2561C30.771 39.2417 29.8877 37.6213 30.6633 36.3729L32.6257 33.2225H28.4985V38.3089C28.4985 39.3385 27.6625 40.1833 26.6241 40.1833C25.5857 40.1833 24.7497 39.3385 24.7497 38.3089V31.3481C24.7497 30.3097 25.5857 29.4649 26.6241 29.4649H31.5169C31.0153 28.1977 30.5577 27.2208 30.1705 26.4817C27.8284 28.4583 25.0012 29.2218 22.2065 27.9689H22.2329C23.5605 27.9689 25.0297 26.5659 23.5353 24.4313C25.3049 25.3341 26.8617 24.5251 28.2873 23.1113C28.1553 22.2313 28.4545 21.2897 29.1673 20.6297C30.3025 19.5737 32.1065 19.6529 33.1625 20.7969C33.5409 21.2017 35.5473 23.5601 37.7385 30.0985C37.9489 30.7014 37.9757 31.7399 37.6065 32.3337L35.6353 35.4929H41.6457C42.6753 35.4929 43.5201 36.3289 43.5201 37.3673Z"
                    fill="#00F7D5"
                  />
                  <path
                    d="M28.9701 18.594C30.7844 18.594 32.2551 17.1232 32.2551 15.309C32.2551 13.4947 30.7844 12.0239 28.9701 12.0239C27.1558 12.0239 25.6851 13.4947 25.6851 15.309C25.6851 17.1232 27.1558 18.594 28.9701 18.594Z"
                    fill="#00F7D5"
                  />
                  <path
                    d="M17.8963 10.4815H3.21403V7.61398C3.87543 7.14098 5.33104 6.42721 6.79915 7.89452C6.99002 8.0854 7.29934 8.0854 7.49021 7.89452C7.68108 7.70365 7.68108 7.39433 7.49021 7.20346C6.72056 6.43381 5.91967 6.11138 5.16895 6.05444V4.6167H1.27979C1.27979 12.2263 1.27979 5.20463 1.27979 19.2786H8.12196C12.1089 19.2786 15.3921 16.2927 15.8741 12.4364H17.8963C18.4362 12.4364 18.8737 11.9988 18.8737 11.459C18.8738 10.919 18.4362 10.4815 17.8963 10.4815ZM12.758 20.256C12.3492 20.256 12.0184 20.5871 12.0184 20.9955V25.5404C12.0184 26.9761 10.8506 28.1443 9.41486 28.1443C7.97914 28.1443 6.81059 26.9762 6.81059 25.5404V23.5222C6.81059 23.1138 6.47979 22.7826 6.07103 22.7826H2.75871V22.1355H1.27979V25.0937H2.75871V24.2616H5.33148V25.5403C5.33148 27.7918 7.16303 29.6232 9.41486 29.6232C11.666 29.6232 13.4974 27.7918 13.4974 25.5403V20.9955C13.4974 20.5871 13.1667 20.256 12.758 20.256ZM19.628 18.5577C18.9397 17.5834 17.5795 17.3495 16.6053 18.0378C16.5521 18.0753 16.5078 18.1066 16.4698 18.1467L18.0038 19.1341C18.3682 19.368 18.4653 19.8437 18.2404 20.2018C18.0066 20.5661 17.5307 20.6632 17.1664 20.4293L15.7179 19.4877C15.6331 20.0255 15.7474 20.582 16.0853 21.0603C16.5449 21.7107 17.3034 22.022 18.047 21.9584L21.3506 26.6344C21.6961 27.1236 22.3728 27.2399 22.8619 26.8943C23.3511 26.5488 23.4674 25.8721 23.1218 25.383L19.8186 20.7074C20.1275 20.0277 20.0877 19.2083 19.628 18.5577Z"
                    fill="#00F7D5"
                  />
                </svg>
              </div>

              <div className="flex flex-col gap-2">
                {editContent ? (
                  <div className="relative">
                    <button
                      onClick={() =>
                        toggleEditModal(
                          "about",
                          "values_3_title",
                          corevalues && corevalues[2]?.value
                        )
                      }
                      className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[0.5rem] -left-10"
                    >
                      <Icon icon="tabler:edit" width="20" height="20" />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                <h3 className="font-semibold text-xl leading-normal text-secondaryblue">
                  {corevalues && corevalues[2]?.value}
                </h3>

                {editContent ? (
                  <div className="relative">
                    <button
                      onClick={() =>
                        toggleEditModal(
                          "about",
                          "values_3_description",
                          corevalues && corevalues[2]?.description
                        )
                      }
                      className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[0.5rem] -left-10"
                    >
                      <Icon icon="tabler:edit" width="20" height="20" />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                <p className="text-secondaryblue leading-[25px] text-base font-light">
                  {corevalues && corevalues[2]?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BeforeFooter edit={editContent} />
    </Layout>
  );
}
export default About;
 