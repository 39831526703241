
import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { Button } from "../button/button";
import { Input } from "../input/input";

export const ContactForm = () => {
  const location = useLocation();

  const [formdata, setFormData] = useState({
    fullname: "",
    email: "",
    message: "",
  });

const handleformSubmit = (e) => {
    e.preventDefault();
    if (
      formdata.email.trim().length === 0 ||
      formdata.fullname.trim().length === 0 ||
      formdata.message.trim().length === 0
    ) {
      alert("Values cannot be empty");
    } else {
      const body = {
        name: formdata.fullname,
        email: formdata.email,
        subject: "Contact Message.",
        mssg: formdata.message
      }
      const requestOptions = {
        method: "POST",
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(body)
      }

      fetch(`${process.env.REACT_APP_CONTACT_SERVICE_API}/api/contact/add`, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data) {
          alert("Message Sent Successfully");
          setFormData({
            fullname: "",
            email: "",
            message: "",
          });
        }
      })
      .catch(err => console.log(err))
	}
  };

  return (
    <>
      <div className="flex flex-col gap-6 w-full lg:p-4">
        <h2 className="pageHeaderTitle !max-w-full !text-4xl text-start md:text-center lg:text-left">
          Kontakt os
        </h2>
          <form
          className="w-full flex flex-col gap-5 items-center justify-center"
          onSubmit={handleformSubmit}
        >
          <div className="flex flex-col w-full gap-5">
            <Input
              value={formdata.fullname}
              onChange={(e) =>
                setFormData({
                  ...formdata,
                  fullname: e.target.value,
                })
              }
              placeholder={"Full name"}
              type={"text"}
            />
            <Input
              value={formdata.email}
              onChange={(e) =>
                setFormData({
                  ...formdata,
                  email: e.target.value,
                })
              }
              placeholder={"Email"}
              type={"email"}
            />
            <textarea
              className="w-full p-4 rounded-[10px] outline-none border-[1px] border-primaryblue hover:border-secondaryblue focus:border-primaryblue"
              placeholder="Message"
              value={formdata.message}
              onChange={(e) =>
                setFormData({
                  ...formdata,
                  message: e.target.value,
                })
              }
              rows={8}
            />
          </div>

          <div className="flex w-full items-start justify-start md:justify-center lg:justify-start">
            <Button
              className={`${
                location.pathname === "/" ? "bg-primarygreen" : "bg-primaryblue"
              } text-white hover:bg-secondaryblue font-medium`}
              type={"submit"}
            >
              Sende
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
 