
import React from 'react';
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";

import { navlinks } from "../data/data";
import { Logo } from "./logo";
import { Navlinks } from "./navigation/navlinks";
import { useDispatch, useSelector } from "react-redux";
import { closeEdit, enableEdit } from "../redux/generalReducer";
import { authData } from '../redux/authreducer';

export const Header = () => {
  const [sidebar, setShowSidebar] = useState(false);

  const editor = useSelector((state) => state.general.editcontent);

  const auth = useSelector((state) => state.auth.auth);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const toggleEditor = () => {
    if (editor === false) {
      dispatch(enableEdit());
    } else {
      dispatch(closeEdit());
    }
  };

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/auth/tokens`;

  const Logout = () => {
    const defaults = {
      headers: Object.assign({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${auth?.access_token}`,
      }),
      method: "DELETE",
    };

    fetch(url, defaults).then((res) => {
      if (res.status === 401) {
        alert("Error Logging out");
      } else {
        dispatch(authData(null));
        navigate("/");
      }
    });
    };

  return (
    <>
      <div
        className="p-4 px-8 flex fixed w-full top-0 z-50 bg-white items-center justify-center"
        style={{ boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <div className="flex items-center justify-between w-full max-w-7xl">
          <Logo />
          <div className="hidden lg:flex items-center gap-8">
            {navlinks.map((n, index) => (
              <Navlinks key={index} to={n.link} label={n.label} />
            ))}
          </div>
          <div className="flex items-center gap-4">
            <div className="gtranslate_wrapper"></div>
            <button className="py-2 px-6 hidden lg:flex bg-primaryblue text-white font-medium rounded-[10px]"
            onClick={() => navigate("/contact")}>
              Kom i gang
            </button>
            {auth === null ? (
              <></>
            ) : (
              <button
                className="flex items-center font-medium gap-3 bg-secondaryblue py-2 px-6 text-white rounded-[10px] outline-none"
                onClick={() => Logout()}
              >
                Logout
                <Icon
                  icon="ri:logout-circle-r-fill"
                  color="red"
                  width="28"
                  height="28"
                />
              </button>
            )}
            <button
              className="lg:hidden flex"
              onClick={() => setShowSidebar(!sidebar)}
            >
              {sidebar === true ? (
                <Icon icon="material-symbols:close" width="24" height="24" />
              ) : (
                <Icon icon="material-symbols:menu" width="24" height="24" />
              )}
            </button>
          </div>
        </div>
      </div>
       {auth === null ? (
        <></>
      ) : location.pathname === "/about" ||
        location.pathname === "/contact" ||
        location.pathname === "/services" ? (
        <div className="fixed bg-white top-36 right-0">
          <button
            onClick={() => toggleEditor()}
            className="bg-secondaryblue p-2 text-white font-medium"
          >
            Edit Content
          </button>
        </div>
      ) : (
        <></>
      )}
      {sidebar && (
        <div className="bg-white fixed top-24 z-20 w-full h-full p-4">
          <div className="flex flex-col gap-7 p-4">
            {navlinks.map((n, index) => (
              <Navlinks key={index} to={n.link} label={n.label} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
 