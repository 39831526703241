
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const Contact_Slice = createAsyncThunk("data/contact", async (token) => {
  const res = await fetch(
    `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/profile`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-API-Key": token,
      },
    }
  );
  const data = await res.json();
  return data;
});

const FetchContact = createSlice({
  name: "contact_slice",
  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(Contact_Slice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Contact_Slice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(Contact_Slice.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default FetchContact.reducer;


 