
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";

import { hideModal } from "../../redux/generalReducer";
import { Contact_Slice } from "../../redux/contact_slice";
import { CoreValues_Slice } from "../../redux/corevalues_slice";
import { Address_Slice } from "../../redux/address_slice";
import { Offering_Slice } from "../../redux/offerings_slice";

export const ModalEditContent = ({ onCancel }) => {
  const dispatch = useDispatch();

  const fieldName = useSelector((state) => state.general.contentToEdit);
  const objectName = useSelector((state) => state.general.objectName);
  const edit = useSelector((state) => state.general.editedContent);
  const corevalues = useSelector((state) => state.corevalues.data);
  const contact = useSelector((state) => state.contact.data);
  const address = useSelector((state) => state.address.data);
  const services = useSelector((state) => state.offerings.data);
  const open = useSelector((state) => state.general.modalstate);

  const [editedContent, setEditedContent] = useState(edit);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEditedContent(edit);
  }, [edit]);

  useEffect(() => {}, [contact, objectName, fieldName]);

  const location = useLocation();

  const handleSubmit = async () => {
    setLoading(true);
    if (location.pathname === "/contact") {
      let payload;
      if (fieldName === "contactEmail") {
        payload = {
          id: contact?.id,
          contact_email: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/profile`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Contact_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "contactPhone") {
        payload = {
          id: contact?.id,
          phone: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/profile`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Contact_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "footermessage") {
        payload = {
          id: address && address[0]?.id,
          address: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/address/1`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Address_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      }
    } else if (location.pathname === "/about") {
      let payload;
      if (fieldName === "about_description") {
        payload = {
          id: contact?.id,
          about: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/profile`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Contact_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "mission") {
        payload = {
          id: contact?.id,
          mission_statement: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/profile`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Contact_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "values") {
        payload = {
          id: contact?.id,
          vision: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/profile`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Contact_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "values_1_title") {
        payload = {
          core_values: [
            {
              id: corevalues && corevalues[0]?.id,
              value: editedContent,
            },
          ],
        };
        try {
          await fetch(
            `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/core-values`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              },
              body: JSON.stringify(payload),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(CoreValues_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "values_1_description") {
        payload = {
          core_values: [
            {
              id: corevalues && corevalues[0]?.id,
              description: editedContent,
            },
          ],
        };
        try {
          await fetch(
            `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/core-values`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              },
              body: JSON.stringify(payload),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(CoreValues_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "values_2_title") {
        payload = {
          core_values: [
            {
              id: corevalues && corevalues[1]?.id,
              value: editedContent,
            },
          ],
        };
        try {
          await fetch(
            `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/core-values`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              },
              body: JSON.stringify(payload),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(CoreValues_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "values_2_description") {
        payload = {
          core_values: [
            {
              id: corevalues && corevalues[1]?.id,
              description: editedContent,
            },
          ],
        };
        try {
          await fetch(
            `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/core-values`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              },
              body: JSON.stringify(payload),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(CoreValues_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "values_3_title") {
        payload = {
          core_values: [
            {
              id: corevalues && corevalues[2]?.id,
              value: editedContent,
            },
          ],
        };
        try {
          await fetch(
            `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/core-values`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              },
              body: JSON.stringify(payload),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(CoreValues_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "values_3_description") {
        payload = {
          core_values: [
            {
              id: corevalues && corevalues[2]?.id,
              description: editedContent,
            },
          ],
        };
        try {
          await fetch(
            `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/core-values`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              },
              body: JSON.stringify(payload),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(CoreValues_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "footermessage") {
        payload = {
          id: address && address[0]?.id,
          address: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/address/1`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Address_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      }
    } else if (location.pathname === "/") {
      let payload;
      if (fieldName === "footermessage") {
        payload = {
          id: address && address[0]?.id,
          address: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/address/1`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Address_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      }
    } else if (location.pathname === "/services") {
      let payload;
      if (fieldName === "footermessage") {
        payload = {
          id: address && address[0]?.id,
          address: editedContent,
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/address/1`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Address_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "servicesrendered_1") {
        payload = {
          offerings: [
            {
              id: services && services[0]?.id,
              offering: editedContent,
            },
          ],
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/offerings`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Offering_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "servicesrendered_1_description") {
        payload = {
          offerings: [
            {
              id: services && services[0]?.id,
              description: editedContent,
            },
          ],
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/offerings`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Offering_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "servicesrendered_2") {
        payload = {
          offerings: [
            {
              id: services && services[1]?.id,
              offering: editedContent,
            },
          ],
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/offerings`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Offering_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      } else if (fieldName === "servicesrendered_2_description") {
        payload = {
          offerings: [
            {
              id: services && services[1]?.id,
              description: editedContent,
            },
          ],
        };
        try {
          await fetch(`${process.env.REACT_APP_COMPANY_SERVICE_API}/company/offerings`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.length === 0) {
                setLoading(false);
                alert("Error fetching data");
              } else {
                setLoading(false);
                dispatch(Offering_Slice(`${process.env.REACT_APP_API_KEY}`));
              }
            })
            .finally(() => {
              dispatch(hideModal());
            });
        } catch (error) {}
      }
    }
  };

  return (
    <>
      <div
        className={`${
          open
            ? "inset-0 fixed z-40 bg-black bg-opacity-60 w-full flex items-center p-3 md:p-0 justify-center h-full"
            : "hidden"
        }`}
      >
        <div className="bg-white relative top-10 p-4 w-full flex flex-col gap-5 max-w-lg rounded-[10px]">
          <div className="flex p-2 items-center justify-between">
            <h2 className="font-semibold text-2xl leading-[25px]">
              Edit {location.pathname} contents
            </h2>

            <button
              onClick={() => dispatch(hideModal())}
              className="bg-[#D9D9D9] p-1 rounded-[10px]"
            >
              <Icon icon="mingcute:close-fill" width={20} height={20} />
            </button>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            className="w-full flex flex-col gap-5 items-center justify-center p-2"
          >
            <textarea
              type="text"
              value={editedContent}
              rows={6}
              className="w-full p-4 rounded-[10px] outline-none border-[1px] border-primaryblue hover:border-secondaryblue focus:border-primaryblue"
              onChange={(e) => {
                setEditedContent(e.target.value);
              }}
            />

            <div className="flex items-center gap-4 w-full">
              <button
                className="bg-primarygreen outline-none px-4 py-2 text-white font-medium rounded-md"
                type="submit"
              >
                Save
              </button>
              <button
                type="button"
                className="bg-red-500 px-4 outline-none py-2 text-white font-medium rounded-md"
                onClick={() => dispatch(hideModal())}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
 