
import React from 'react'

export default function GoogleMap () {
  return (
    <div className="flex h-full">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2251.051570585823!2d12.179695076861597!3d55.65331197304753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46525edb454c54f9%3A0xfc9eb417cbab4850!2sRefmosevej%201A%2C%202640%20Hedehusene%2C%20Denmark!5e0!3m2!1sen!2sng!4v1696326169373!5m2!1sen!2sng"
        width="100%"
        height="597"
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
        className="h-full"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
 