
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const Address_Slice = createAsyncThunk("data/address", async (token) => {
  const res = await fetch(
    `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/address`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-API-Key": token,
      },
    }
  );
  const data = await res.json();
  return data;
});

const FetchAddresses = createSlice({
  name: "address_slice",
  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(Address_Slice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Address_Slice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(Address_Slice.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default FetchAddresses.reducer;

 