
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const Offering_Slice = createAsyncThunk(
  "data/offerings_slice",
  async (token) => {
    const res = await fetch(
      `${process.env.REACT_APP_COMPANY_SERVICE_API}/company/offerings`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-API-Key": token,
        },
      }
    );
    const data = await res.json();
    return data;
  }
);

const FetchOfferings = createSlice({
  name: "offerings_slice",
  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(Offering_Slice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Offering_Slice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(Offering_Slice.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default FetchOfferings.reducer;



 