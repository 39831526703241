
import React from 'react';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Layout } from '../../components/layout';
import ContactImage_1 from '../../assets/IMG-20231001-WA0019.jpg';
import { openModal, setObjeName } from '../../redux/generalReducer';

import useDocumentTitle from '../../hooks/useTitle';
import useScrollTop from '../../hooks/useScrollToTop';

function Contact () {
  const contents = useSelector((state) => state.contact.data);
  const contact = useSelector((state) => state.general.contact);
  const editContent = useSelector((state) => state.general.editcontent);

  useDocumentTitle("Contact Us | MozartrenDK");
  useScrollTop();

  const dispatch = useDispatch();

  const toggleEditModal = (objectName, fieldName, fieldValue) => {
    dispatch(setObjeName(objectName));
    dispatch(openModal({ field: fieldName, value: fieldValue }));
  };

  return (
    <Layout>
      <div className="w-full flex items-center justify-center h-full py-12 p-4 px-8 lg:h-[636px] bg-secondaryyellow">
        <div className="w-full max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-7">
          <div className="flex flex-col gap-4 items-start md:items-center lg:items-start justify-center">
            <div className="flex flex-col gap-4 items-start md:items-center lg:items-start">
              <h2 className="pageHeaderTitle">{contact.contactTitle}</h2>

              <div className="flex lg:hidden">
                <img
                  src={ContactImage_1}
                  className="w-full lg:w-[500px] lg:h-[500px] object-cover"
                  alt="contactImage_1"
                />
              </div>

              <p className="pageDescription !w-full !max-w-xl !font-light">
                {contact.contactDescription}
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6 w-full md:max-w-lg lg:max-w-xs">
              {editContent ? (
                <div className="relative">
                  <button
                    onClick={() =>
                      toggleEditModal(
                        "contact",
                        "contactEmail",
                        contents.contact_email
                      )
                    }
                    className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[1.6rem] -left-12"
                  >
                    <Icon icon="tabler:edit" width="20" height="20" />
                  </button>
                </div>
              ) : (
                <></>
              )}
              <button className="contactbuttonemail">
                <Icon icon="ic:baseline-email" width="24" height="24" />
                <span>{contents?.contact_email}</span>
              </button>

              {editContent ? (
                <div className="relative">
                  <button
                    onClick={() =>
                      toggleEditModal("contact", "contactPhone", contents.phone)
                    }
                    className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[1.6rem] -left-12"
                  >
                    <Icon icon="tabler:edit" width="20" height="20" />
                  </button>
                </div>
              ) : (
                <></>
              )}
              <div className="flex gap-4 items-center">
                <button className="contactbuttoncall !w-full">
                  <>
                    <Icon icon="bxs:phone-call" width="24" height="24" />
                    <span>{contents?.phone}</span>
                  </>
                </button>
                <Link
                  to={"https://www.facebook.com/Mozareng"}
                  target="_blank"
                  className="text-white"
                >
                  <Icon icon="ic:round-facebook" width="32" height="32" />
                </Link>
              </div>
            </div>
          </div>

          <div className="hidden lg:flex">
            <img src={ContactImage_1} className="w-[500px] h-[500px] object-cover" alt="contactImage_1" />
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center p-4 px-8">
      </div>
    </Layout>
  );
}
export default Contact;
 