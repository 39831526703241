import * as ROUTES from "../constants/routes";

export const navlinks = [
  {
    link: ROUTES.HOME,
    label: "Hjem",
  },
  {
    link: ROUTES.ABOUT,
    label: "Om",
  },
  {
    link: ROUTES.SERVICES,
    label: "Tjenester",
  },
  {
    link: ROUTES.CONTACT,
    label: "Kontakt",
  },
];

export const HomeSecondSectionData = [
  {
    icon: "bi:phone-fill",
    title: "Opkaldstider",
    description: "Mandag-lørdag 8.00 - 18.00",
  },
  {
    icon: "material-symbols:dry-cleaning",
    title: "Privat rengøring",
    description:
      "Vores team af erfarne rengøringsassistenter er dedikeret til at give vores kunder et pletfrit og forfrisket hjem hver gang.",
  },
  {
    icon: "eos-icons:cleanup",
    title: "Erhverv Rengøring",
    description:
      "Vores team af erfarne rengøringsassistenter er dedikeret til at give vores kunder et rent, sikkert og produktivt arbejdsmiljø",
  },
];
