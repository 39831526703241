
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";

import { ContactForm } from "./forms/contactform";
import { Logo } from "./logo";
import GoogleMap from "./map/map";
import { openModal, setObjeName } from "../redux/generalReducer";

export const Footer = () => {
  const editContent = useSelector((state) => state.general.editcontent);
  const footercontents = useSelector((state) => state.contact.data);
  const address = useSelector((state) => state.address.data);

  const contact = useSelector((state) => state.contact.data);

  const dispatch = useDispatch();

  const toggleEditModal = (objectName, fieldName, fieldValue) => {
    dispatch(setObjeName(objectName));
    dispatch(openModal({ field: fieldName, value: fieldValue }));
  };

  const location = useLocation();

  return (
    <>
      <div>
        <div className="w-full flex items-center justify-center h-full lg:h-[597px] mb-24">
          <div className="w-full grid grid-cols-1 h-full lg:grid-cols-2">
            <div className="h-[40rem] lg:h-full">
              <GoogleMap />
            </div>

            <div
              className={`py-10 px-8 ${
                location.pathname === "/"
                  ? "bg-primaryblue"
                  : location.pathname === "/about"
                  ? "bg-primarygreen"
                  : location.pathname === "/services"
                  ? "bg-secondarypink"
                  : "bg-secondaryyellow"
              } flex items-center justify-center`}
            >
              <div className="w-full max-w-2xl">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>

        <div className="py-16 lg:p-4 h-full lg:h-[585px] bg-primaryblue px-8 flex gap-36 flex-col items-center justify-center">
          <div className="flex flex-col lg:flex-row lg:gap-0 gap-10 items-start w-full max-w-7xl">
            <div className="flex flex-col items-start justify-between w-full">
              <div className="w-full flex flex-col gap-4">
                <div className="flex items-center gap-5">
                <div className="">
                  <Logo />
                </div>
                <div>
                  <h2 className="pageHeaderTitle !text-left">Mozart Rengøring</h2>
                </div>
                </div>
                </div>

                <div className="relative flex gap-1.5 mt-3 flex-col">
                  <p className="w-full max-w-full !text-left md:!max-w-md lg:!max-w-sm !leading-[25px] !text-base  pageDescription">
                    Velkommen til Mozart Rengøring! Vi sætter en ære i at være dit bedste valg til at skabe forfriskende funklende huse.
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-7 md:gap-y-10 lg:gap-7 w-full justify-between">
                <div className="flex flex-col gap-4 lg:gap-y-10">
                  <h3 className="pageDescription !text-left !font-semibold !text-xl !leading-[22px]">
                    Tjenester
                  </h3>

                  <div className="flex flex-col gap-4">
                    <Link className="pageDescription !text-left" to={"/"}>
                      Privat Rengøring
                    </Link>
                    <Link className="pageDescription !text-left" to={"/"}>
                      Kontor  Rengøring
                    </Link>
                  </div>
                </div>

                <div className="flex flex-col gap-4 lg:gap-y-10">
                  <h3 className="pageDescription !text-left !font-semibold !text-xl !leading-[22px]">
                    Links
                  </h3>

                  <div className="flex flex-col gap-4">
                    <Link className="pageDescription !text-left" to={"/about"}>
                      Om
                    </Link>
                    <Link className="pageDescription !text-left" to={"/services"}>
                      Tjenester
                    </Link>
                    <Link className="pageDescription !text-left" to={"/contact"}>
                      Kontakt
                    </Link>
                  </div>
                </div>

                <div className="flex flex-col gap-4 lg:gap-y-10">
                  <h3 className="pageDescription !text-left !font-semibold !text-xl !leading-[22px]">
                    Kontakt
                  </h3>

                  <div className="flex flex-col gap-4">
                   <Link
                      to={"/"}
                      className="pageDescription !font-medium !flex !items-center !gap-2"
                    >
                      <Icon icon="bxs:phone-call" width="20" height="20" />
                      <span>{contact?.phone}</span>
                    </Link>

                   <Link
                      to={"/"}
                      className="pageDescription !text-secondaryyellow font-semibold flex items-center !gap-2"
                    >
                      <Icon icon="ic:baseline-email" width="20" height="20" />
                      <span>{contact?.contact_email}</span>
                    </Link>

                     <div className="relative">
                      {editContent ? (
                        <div className="relative">
                          <button
                            onClick={() =>
                              toggleEditModal(
                                "footer",
                                "footermessage",
                                address && address[0]?.address
                              )
                            }
                            className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-[0rem] -left-[2rem]"
                          >
                            <Icon icon="tabler:edit" width="20" height="20" />
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                      <Link
                        to={"/"}
                        className="!text-secondaryblue font-semibold flex items-start !gap-2"
                      >
                      <div className="mt-0">
                      
                        <Icon icon="mdi:location" width="20" height="20" />
                      </div>
                        <span className="pageDescription">{address && address[0]?.address}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center lg:flex-row flex-col gap-4 justify-between w-full max-w-7xl bg-secondaryyellow p-5">
            <p className="text-sm">
              Designed by{" "}
              <a
                target="_blank"
                className="font-semibold"
                href="https://luday.se"
                rel="noreferrer"
              >
                LUDAY AB
              </a>
            </p>
            <p className="text-sm">Copyright @ Mozart Rengøring {new Date().getFullYear()}</p>
          </div>
          </div>
        </div>
    </>
  );
};
 