
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalstate: false,
  editcontent: false,
  editedContent: "",
  contact: {
    contactspan: "contact us",
    contactTitle: "Kontakt os i dag",
    contactDescription:
      "Kontakt os, så svarer vi så hurtigt vi kan.",
    contactEmail: "info@mozartren.dk",
    contactPhone: "(+45)71642736",
    contactSectionspan: "OUR OFFICES",
    contactSectionTitle: "Come and visit our offices around the globe",
  },
  services: {
    servicesTitle: "Udsøgt rengøringsservice til privat og erhverv",
    servicesDescription:
      "Mozart Rengøring løfter dine omgivelser med sine omhyggelige rengøringsløsninger. Fra dit hjem til kontorer, vores udsøgte tjenester sikrer en uberørt stemning, der efterlader et varigt indtryk. Få en god oplevlse. Vores mål er din tilfredshed.",
    servicesrendered_1: "Specialty Cleaning",
    servicesrendered_1_description:
      "We provide a variety of speciality cleaning services designed to improve both your working environment and your image. We may combine our speciality services with other services to reduce costs even more.",
    servicesrendered_2: "Gardening & Landscaping",
    servicesrendered_2_description:
      "Our designer will construct a landscape design that is unique to you and your property. We begin where the lawn terminates and the garden begins. Clean-ups in the spring and fall, mulching, planting, edging, trimming, perennial division, and design.",
    servicesrendered_3: "House Cleaning for Professionals",
    servicesrendered_3_description:
      "We know the preferences of every homeowner will differ, which is why we proudly offer customizable cleaning programs. No matter whether you need us to come by before a special event, spruce up your home before the holidays.",
    servicesrendered_4: "Fumigation/Pest Control",
    servicesrendered_4_description:
      "Finicky Clean Limited focuses primarily on the control and eradication of pests common in Nigeria using only environmentally friendly products. Some of these pests include bed bugs, termites, rats, houseflies among others.",
  },
  cta: {
    title: "Kom i kontakt med",
    description:
      "Vi er her for at besvare eventuelle spørgsmål, du måtte have om Mozart Rengøring Limited erfaring. Kontakt os, så svarer vi så hurtigt vi kan.",
  },
  footer: {
    footermessage:
      "Welcome to Mozart Rengøring! We take pride in being your premier choice for creating refreshingly sparkling houses.",
  },
  about: {
    heading: "Om vores rengøringsfirma",
    about_description:
      "Mozart Rengøring er en dynamisk og innovativ rengøringsvirksomhed, der har specialiseret sig i at levere premium rengøringsydelser til virksomheder og privat. Efter mange års værdifuld erfaring med arbejde i fremtrædende rengøringsfirmaer, tog vi initiativet til at tilbyde vores ekspertise og viden til flest mulige. Vores forretning er centreret omkring at tilbyde omfattende kommercielle rengøringsløsninger, der prioriterer renlighed, hygiejne og professionalisme. Med en stor forståelse for branchens bedste praksis er vi forpligtet til at levere exceptionelle rengøringstjenester, der opfylder vores kunders specifikke behov. Vores mission er at skabe og vedligeholde uberørte arbejdsmiljøer og hjem, der fremmer produktivitet, velvære, afslapning og tilfredshed for vores kunder.",
    mission:
      "To respond timely to our client’s request and effectively deliver our service through professionalism and innovation.",
    values: "The core values that drive everything we do",
    values_1_title: "Client first",
    values_1_description:
      "Cleaning solutions centered around your needs. Experience the client-first difference!",
    values_2_title: "Dedication",
    values_2_description:
      "Your spotless space, our commitment. Discover cleaning excellence with our dedicated services.",
    values_3_title: "Hard work",
    values_3_description:
      "Transformimg mess into freshness. Experience cleaning excellence through our hard work.",
  },
  home: {
    home_title:
      "Ethvert hjem og arbejdsmiljø har brug for perfekt rengøring. Mozart Rengøring bekymrer sig.",
    home_description:
      "Et rent og organiseret rum hjælper med at forbedre fokus, produktivitet og søvnkvalitet, forbedre en persons humør samt giver en følelse af præstation og tilfredshed. Oplev upåklagelig rengøring til dit hjem og kontor med Mozart Rengøring. Vores mål er din tilfredshed.",
    home_about_title:
      "Strålende rent: Dit førstevalg til funklende hjem og kontorer",
    home_about_description:
      "Velkommen til Mozart Rengøring! Vi sætter ære i at være dit bedste valg til at skabe forfriskende og funklende hjem og kontorer. Vores dedikerede team af rengøringseksperter er forpligtet til at forvandle dine opholds- og arbejdsområder til uberørte helligdomme ved at bruge top-tier metoder og miljøvenlige produkter. Oplev glæden ved at komme til et forfriskende rent hjem eller kontor, som revitaliserer dine sanser og forbedrer dit velvære.",
    home_third_section_title:
      "Planlæg din rengøring! Vi tager os af resten",
    home_third_section_description:
      "Omfavn en pletfri bolig. Planlæg din rengøringsservice nu for et forfriskende rent hjem og arbejdsmiljø. Din tilfredshed er vores prioritet.",
  },
  contentToEdit: "",
  objectName: "",
};

export const GeneralReducer = createSlice({
  name: "general",
  initialState: initialState,
  reducers: {
    openModal: (state, action) => {
      state.modalstate = true;
      state.contentToEdit = action.payload.field;
      state.editedContent = action.payload.value;
      state.object = action.payload.objectName;
    },
    hideModal: (state, action) => {
      state.modalstate = false;
      state.contentToEdit = null;
    },
    enableEdit: (state, action) => {
      state.editcontent = true;
    },
    closeEdit: (state, action) => {
      state.editcontent = false;
    },
    setObjeName: (state, action) => {
      state.objectName = action.payload;
    },
    updateContent: (state, action) => {
      const { objectName, fieldName, value } = action.payload;
      if (state[objectName] && state[objectName].hasOwnProperty(fieldName)) {
        state[objectName][fieldName] = value;
      }
    },
  },
});

export const {
  hideModal,
  openModal,
  enableEdit,
  closeEdit,
  updateContent,
  setObjeName,
} = GeneralReducer.actions;

export default GeneralReducer.reducer;

 