
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { About, Contact, Home, Services } from "../pages";

import * as ROUTES from "../constants/routes";
import Auth from "../pages/authentication/auth";

const AppRouter = () => (
  <>
    <Routes>
      <Route element={<Home />} path={ROUTES.HOME} />
      <Route element={<About />} path={ROUTES.ABOUT} />
      <Route element={<Services />} path={ROUTES.SERVICES} />
      <Route element={<Contact />} path={ROUTES.CONTACT} />
      <Route element={<Auth />} path={ROUTES.SIGNIN} />
    </Routes>
  </>
);

export default AppRouter;
 