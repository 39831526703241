
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';

import { Layout } from '../../components/layout';
import { openModal, setObjeName } from '../../redux/generalReducer';

import ServicesImage_1 from '../../assets/IMG-20231001-WA0018.jpg';
import Specialty from '../../assets/IMG-20231001-WA0024.jpg';
import Gardening from '../../assets/IMG-20231001-WA0017.jpg';
import { BeforeFooter } from '../../components/other/beforeFooter';

import useDocumentTitle from '../../hooks/useTitle';
import useScrollTop from '../../hooks/useScrollToTop';

function Services() {
  const contents = useSelector((state) => state.general.services);
  const editContent = useSelector((state) => state.general.editcontent);
  const services = useSelector((state) => state.offerings.data);

  useDocumentTitle("Services | Mozart Rengøring");
  useScrollTop();

  const dispatch = useDispatch();

  const toggleEditModal = (objectName, fieldName, fieldValue) => {
    dispatch(setObjeName(objectName));
    dispatch(openModal({ field: fieldName, value: fieldValue }));
  };

  return (
    <Layout>
      <div className="w-full flex items-center justify-center h-[500px] py-14 lg:py-0 p-4 px-8 lg:h-[475px] bg-white">
        <div className="w-full max-w-7xl flex items-center justify-center flex-col gap-5">
          <div>
            <h2 className="pageHeaderTitle !w-full !text-center !max-w-2xl !leading-[44px] lg:!leading-[58px]">
              {contents.servicesTitle}
            </h2>
          </div>

          <div>
            <p className="pageDescription font-light !w-full !max-w-[40rem] !text-center">
              {contents.servicesDescription}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white lg:h-[602px] w-full relative">
        <img
          src={ServicesImage_1}
          alt="servicesImage"
          className="lg:h-[602px] relative lg:object-cover w-full"
        />
      </div>

      <div className="w-full flex justify-center h-full p-4 px-8 py-20 bg-white">
        <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 gap-16">
          {/* specialty cleaning */}
          <div className="flex flex-col gap-10 p-5 w-fit">
            <div className="flex flex-col gap-3">
              {editContent ? (
                <div className="relative">
                  <button
                    onClick={() =>
                      toggleEditModal(
                        "services",
                        "servicesrendered_1",
                        services && services[0]?.offering
                      )
                    }
                    className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-4 -left-12"
                  >
                    <Icon icon="tabler:edit" width="20" height="20" />
                  </button>
                </div>
              ) : (
                <></>
              )}
              <h2 className="pageHeaderTitle !text-3xl !w-full !max-w-full">
                {services && services[0]?.offering}
              </h2>

              {editContent ? (
                <div className="relative">
                  <button
                    onClick={() =>
                      toggleEditModal(
                        "services",
                        "servicesrendered_1_description",
                        services && services[0]?.description
                      )
                    }
                    className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-4 -left-12"
                  >
                    <Icon icon="tabler:edit" width="20" height="20" />
                  </button>
                </div>
              ) : (
                <></>
              )}
              <p className="pageDescription font-light !w-full !max-w-lg">
                {services && services[0]?.description}
              </p>
            </div>
            <div>
              <img src={Gardening} alt="service images" />
            </div>
          </div>

          {/* garderning */}
          <div className="flex flex-col gap-10 p-5 w-fit">
            <div className="flex flex-col gap-3">
              {editContent ? (
                <div className="relative">
                  <button
                    onClick={() =>
                      toggleEditModal(
                        "services",
                        "servicesrendered_2",
                        services && services[1]?.offering
                      )
                    }
                    className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-4 -left-12"
                  >
                    <Icon icon="tabler:edit" width="20" height="20" />
                  </button>
                </div>
              ) : (
                <></>
              )}
              <h2 className="pageHeaderTitle !text-3xl !w-full !max-w-full">
                {services && services[1]?.offering}
              </h2>

              {editContent ? (
                <div className="relative">
                  <button
                    onClick={() =>
                      toggleEditModal(
                        "services",
                        "servicesrendered_2_description",
                        services && services[1]?.description
                      )
                    }
                    className="p-1 bg-primarygreen rounded-[10px] outline-none absolute top-4 -left-12"
                  >
                    <Icon icon="tabler:edit" width="20" height="20" />
                  </button>
                </div>
              ) : (
                <></>
              )}
              <p className="pageDescription font-light !w-full !max-w-lg">
                {services && services[1]?.description}
              </p>
            </div>
            <div>
              <img src={Specialty} alt="service images" />
            </div>
          </div>
        </div>
      </div>

      <>
        <BeforeFooter edit={editContent} toggle={() => toggleEditModal()} />
      </>
    </Layout>
  );
}
export default Services;
 