
/* API routes */

/* public routes */
export const HOME = "/";
export const ABOUT = "/about";
export const SERVICES = "/services";
export const CONTACT = "/contact";
export const SIGNIN = "/signin";

/** User routes */

/* Auth routes */

/* Admin routes */
 