
import React, { useEffect } from 'react';
 import { useDispatch } from 'react-redux';

import AppRouter from './routers/AppRouter';
import { ModalEditContent } from './components/modal/modal';
import { Contact_Slice } from './redux/contact_slice';
import { CoreValues_Slice } from './redux/corevalues_slice';
import { Address_Slice } from './redux/address_slice';
import { Offering_Slice } from './redux/offerings_slice';


function App() {
const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Contact_Slice(`${process.env.REACT_APP_API_KEY}`));
    dispatch(CoreValues_Slice(`${process.env.REACT_APP_API_KEY}`));
    dispatch(Address_Slice(`${process.env.REACT_APP_API_KEY}`));
    dispatch(Offering_Slice(`${process.env.REACT_APP_API_KEY}`));
  }, []);

  return (
    <>
     <ModalEditContent/>
     <AppRouter />
    </>
  );
}

export default App;
