
import React, { useState } from 'react';
import { Logo } from '../../components/logo';
import { Input } from '../../components/input/input';
import { Button } from '../../components/button/button';

import { Buffer } from 'buffer';
import { useDispatch } from 'react-redux';
import { authData } from '../../redux/authreducer';
import { useNavigate } from 'react-router-dom';

function Auth() {

const [formData, setFormData] = useState({
    email: null,
    password: null,
  });

  const dispatch = useDispatch();

  const url = `${process.env.REACT_APP_USER_SERVICE_API}/auth/tokens`;

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const encodedString = Buffer.from(
      `${formData.email}:${formData.password}`
    ).toString("base64");
    const defaults = {
      headers: Object.assign({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedString}`,
      }),
      method: "POST",
    };

    fetch(url, defaults)
      .then(res => res.json())
      .then(data =>{
      if (data?.code === 401) {
          alert("Incorrect email or password");
        } else {
          dispatch(authData(data));
          navigate("/");
        }
      })
      .catch(err => {
        console.log(err)
        alert("error");
      })
  };

  return (
    <div className="w-full h-full min-h-screen p-4 lg:p-0 flex flex-col gap-6 items-center justify-center">
      <Logo />
      <div className="bg-secondaryyellow border-primaryblue border-[1px] w-full max-w-[524px] h-[385px] lg:h-[405px] flex items-center rounded-[10px] p-6 px-8 md:px-12">
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col gap-10 items-center justify-center"
        >
          <div className="flex flex-col w-full gap-8">
            <Input placeholder={'username'} type={'text'} onChange={(e) => setFormData({ ...formData, email: e.target.value, }) } />
            <Input placeholder={'password'} type={'password'} onChange={(e) => setFormData({ ...formData, password: e.target.value, }) } />
          </div>

          <div className="flex w-full items-start justify-start">
            <Button
              className={"bg-primaryblue text-white hover:bg-secondaryblue"}
              type={"submit"}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Auth;
 