
import { Footer } from './footer';
import { Header } from './header';

export const Layout = ({ children }) => {
    return (
        <div>
            <Header />
            <main className="flex items-center flex-col mt-20 justify-center">
                {children}
            </main>
            <Footer />
        </div>
    );
};
 