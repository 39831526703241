
export const Button = ({ children, className, onClick, type }) => {
  return (
    <button
      className={`${className} w-[141px] h-12 rounded-[10px]`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
 