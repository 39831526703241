
import React from 'react';
import { Link, } from "react-router-dom";

export const Navlinks = ({ to, label }) => {
  return (
    <ul>
      <Link
        to={to}
        className={`text-secondaryblue !font-light hover:text-primaryblue hover:!font-medium hover:underline`}
      >
        {label}
      </Link>
    </ul>
  );
};
 