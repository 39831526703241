
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import generalReducer from "./generalReducer";
import authReducer from "./authreducer";
import contact_slice from "./contact_slice";
import corevalues_slice from "./corevalues_slice";
import address_slice from "./address_slice";
import offerings_slice from "./offerings_slice";

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedAuth = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    general: generalReducer,
    auth: persistedAuth,
    contact: contact_slice,
    corevalues: corevalues_slice,
    address: address_slice,
    offerings: offerings_slice,
  },
});

export const persistor = persistStore(store);
 