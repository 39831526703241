
export const Input = ({ placeholder, className, type, value, onChange }) => {
  return (
    <input
      className={`${className} w-full px-4 h-16 rounded-[10px] outline-none border-[1px] border-primaryblue hover:border-secondaryblue focus:border-primaryblue`}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
    />
  );
};
 