
import React from 'react';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../../components/layout';
import { BeforeFooter } from '../../components/other/beforeFooter';
import { HomeSecondSectionData } from '../../data/data';

import HomeImage_1 from '../../assets/Photo2.png';
import HomeImage_2 from '../../assets/IMG-20231001-WA0014.jpg';
import HomeImage_3 from '../../assets/beforefooterimage.png';

import useDocumentTitle from '../../hooks/useTitle';
import useScrollTop from '../../hooks/useScrollToTop';

function Home () {
  const navigate = useNavigate();

  const editContent = useSelector((state) => state.general.editcontent);
  const services = useSelector((state) => state.general.services);
  const home = useSelector((state) => state.general.home);

  useDocumentTitle("Home | MozartrenDK");
  useScrollTop();

  return (
    <Layout>
      <div className="w-full flex items-center justify-center h-[950px] py-10 p-4 px-8 lg:h-[636px] bg-white">
        <div className="w-full max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-7">
          <div className="flex flex-col gap-4 items-start md:items-center lg:items-start justify-center">
            <div className="flex flex-col gap-4 items-start md:items-center lg:items-start">
              <h2 className="pageHeaderTitle w-full !max-w-[37rem] !leading-[42px] lg:!leading-[52px]">
                {home.home_title}
              </h2>

              <div className="flex justify-center lg:hidden">
                <img
                  src={HomeImage_1}
                  className="w-full object-cover"
                  alt="contactImage_1"
                />
              </div>

              <p className="pageDescription w-full !font-light !max-w-xl">
                {home.home_description}
              </p>
            </div>

            <div className="flex justify-start md:justify-center lg:justify-start items-center gap-6 w-full">
              <button
                className="contactbuttonemail !w-fit !py-2 !px-6 !bg-secondarypink !text-white"
                onClick={() => navigate("/services")}
              >
                Se tjenester
              </button>

              <button
                className="contactbuttoncall !w-fit !py-2 !px-6"
                onClick={() => navigate("/contact")}
              >
                Kontakt os
              </button>
            </div>
          </div>

          <div className="hidden lg:flex justify-center">
            <img
              src={HomeImage_1}
              alt="contactImage_1"
              className="w-full lg:w-[500px] h-[500px] object-cover"
            />
          </div>
        </div>
      </div>

      <div className="w-full flex items-center bg-secondaryblue justify-center p-4 py-10 lg:py-0 px-8">
        <div className="w-full max-w-7xl flex items-center justify-center gap-4 h-full lg:h-72 flex-col">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 place-content-between w-full">
            {HomeSecondSectionData.map((h, index) => (
              <div key={index} className="homeSecondSection hover:border-white">
                <span className="bg-white absolute -top-7 -right-1 w-fit p-3 rounded-full">
                  <Icon icon={h.icon} width={24} height={24} />
                </span>
                <div>
                  <div className="flex flex-col items-start md:items-center lg:items-start">
                    <h2 className="pageHeaderTitle !text-xl !text-white">
                      {h.title}
                    </h2>
                    <p className="pageDescription !w-full !max-w-xs font-light !text-white">
                      {h.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center h-full p-4 py-10 px-8 lg:h-[636px] bg-white">
        <div className="w-full max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-16">
          <div className="hidden lg:flex">
            <img
              src={HomeImage_2}
              alt="contactImage_1"
              className="w-full h-full lg:w-[600px] lg:h-[500px] object-cover"
            />
          </div>

          <div className="flex flex-col gap-4 items-start md:items-center lg:items-start justify-center">
            <div className="flex flex-col gap-4 items-start md:items-center lg:items-start">
              <h2 className="pageHeaderTitle w-full text-3xl !leading-[38px] lg:!text-4xl !max-w-xl lg:!leading-[42px]">
                {home.home_about_title}
              </h2>

              <div className="flex lg:hidden">
                <img
                  src={HomeImage_2}
                  className="w-full lg:w-[500px] lg:h-[500px]"
                  alt="contactImage_1"
                />
              </div>

              <p className="pageDescription w-full !max-w-xl !font-light !leading-[25px]">
                {home.home_about_description}
              </p>
            </div>

            <div className="flex items-center gap-6 w-full md:max-w-lg lg:max-w-xs">
              <button
                className="contactbuttonemail !py-2 !px-6 lg:!w-fit !bg-secondarypink !text-white"
                onClick={() => navigate("/about")}
              >
                Læs meres
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center h-full p-4 py-10 lg:py-0 px-8 bg-secondaryyellow bg-opacity-30 lg:h-[636px]">
        <div className="w-full max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-16">
          <div className="flex flex-col gap-4 items-start md:items-center lg:items-start justify-center">
            <div className="flex flex-col gap-4 items-start md:items-center lg:items-start">
              <h2 className="pageHeaderTitle w-full text-3xl !leading-[38px] !text-center lg:!text-left lg:!text-4xl !max-w-xl lg:!leading-[42px]">
                {home.home_third_section_title}
              </h2>

              <div className="flex lg:hidden">
                <img
                  src={HomeImage_3}
                  className="w-full object-cover"
                  alt="contactImage_1"
                />
              </div>

              <p className="pageDescription w-full !text-center lg:!text-left !max-w-full lg:!max-w-xl  lg:!leading-[25px]">
                {home.home_third_section_description}
              </p>
            </div>

            <div className="flex items-center justify-center lg:justify-start gap-6 w-full md:max-w-lg lg:max-w-xs">
              <button
                className="contactbuttonemail !py-2 px-6 !w-fit !bg-secondarypink !text-white"
                onClick={() => navigate("/contact")}
              >
                Book nu
              </button>

              <button
                className="contactbuttoncall !py-2 px-6 !w-fit"
                onClick={() => navigate("/services")}
              >
                Se tjenester
              </button>
            </div>
          </div>

          <div className="hidden lg:flex justify-center">
            <img
              src={HomeImage_3}
              alt="contactImage_1"
              className="w-[391px] object-cover"
            />
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center h-full py-14 lg:py-0 p-4 px-8 lg:h-[425px] bg-white">
        <div className="w-full max-w-7xl flex items-center justify-center flex-col gap-5">
          <div>
            <h2 className="pageHeaderTitle !w-full !text-center !max-w-2xl !leading-[44px] lg:!leading-[58px]">
              {services.servicesTitle}
            </h2>
          </div>

          <div>
            <p className="pageDescription font-light !w-full !max-w-[40rem] !text-center">
              {services.servicesDescription}
            </p>
          </div>
        </div>
      </div>

      <BeforeFooter edit={editContent} />
    </Layout>
  );
}
export default Home;
 